import TextComponent from "../CustomComponents/TextComponent.vue";
import RadioComponent from "../CustomComponents/RadioComponent.vue";
import DropdownComponent from "../CustomComponents/DropdownComponent.vue";
import EnumerationComponent from "../CustomComponents/EnumerationComponent.vue";
import UploadComponent from "../CustomComponents/UploadComponent.vue";

import CheckBoxComponent from "../CustomComponents/CheckBoxComponent.vue";
import RangeComponent from "../CustomComponents/rangeComponent.vue";

export const formJSON = {
  Text: {
    componentDescription: "Small or long text like title or description",
    basicSettings: [
      {
        components: [
          {
            component: TextComponent,
            id: "input1",
            type: "text",
            label: "Name",
            value: "",
            options: [],
            width: "50%",
          },
        ],
      },
      {
        style: "display: flex",
        components: [
          {
            component: RadioComponent,
            id: "input2", // Ensure IDs are unique
            label: "Options",
            value: "",
            type: "radio",
            options: [
              {
                title: "Short Text",
                description:
                  "Best for titles, names, link(URL). It also enables exact search on the field.",
              },
              {
                title: "Long Text",
                description:
                  "Best for descriptions, biographies. Exact search is disabled.",
              },
            ],
          },
        ],
      },
    ],
    advancedSettings: [
      {
        style: "display: flex",
        components: [
          {
            component: TextComponent,
            id: "input1",
            type: "text",
            label: "Default Value",
            value: "",
            options: [],
            placeholder: "",
          },
          {
            component: TextComponent,
            id: "input2",
            type: "text",
            label: "RegExp Pattern",
            value: "",
            options: [],
            placeholder: "",
          },
        ],
      },
      {
        style: "display: flex",
        componentLabel: "Settings",
        components: [
          {
            component: CheckBoxComponent,
            id: "input2", // Ensure IDs are unique
            label: "Default Value",
            description:
              "You won't be able to create an entry if this field is empty",
            isChecked: false,
            placeholder: "",
          },
          {
            component: CheckBoxComponent,
            id: "input2", // Ensure IDs are unique
            label: "Unique Field",
            description:
              "You won't be able to create an entry if there is an existing entry with identical content",
            isChecked: false,
            placeholder: "",
          },
        ],
      },
      {
        style: "display: flex",
        components: [
          {
            component: RangeComponent,
            width: "60%",
            label: "Minimum Length",
            type: "number",
            id: "input3",
            placeholder: "",
          },
          {
            component: RangeComponent,
            width: "60%",
            label: "Maximum Length",
            type: "number",
            id: "input3",
            placeholder: "",
          },
        ],
      },
      {
        components: [
          {
            component: CheckBoxComponent,
            id: "input4", // Ensure IDs are unique
            label: "Private Field",
            description: "This field will not show up in the API response",
            value: "",
            placeholder: "",
          },
        ],
      },
    ],
  },
  Email: {
    componentDescription: "Email field with validations format",
    basicSettings: [
      {
        style: "display: flex",
        components: [
          {
            component: TextComponent,
            id: "input1",
            type: "text",
            label: "Name",
            value: "",
            options: [],
            width: "50%",
            rule: "No space is allowed for the name of the attribute",
          },
        ],
      },
    ],
    advancedSettings: [
      {
        components: [
          {
            component: TextComponent,
            id: "input1",
            type: "text",
            label: "Default Value",
            value: "",
            options: [],
            width: "50%",
            placeholder: "",
          },
        ],
      },
      {
        style: "display: flex",
        componentLabel: "Settings",
        components: [
          {
            component: CheckBoxComponent,
            id: "input2", // Ensure IDs are unique
            label: "Required Field",
            description:
              "You won't be able to create an entry if this field is empty",
            value: "",
            placeholder: "",
          },
          {
            component: CheckBoxComponent,
            id: "input2", // Ensure IDs are unique
            label: "Unique Field",
            description: "The field can have different values in each language",
            value: "",
            placeholder: "",
          },
        ],
      },
      {
        style: "display: flex",
        components: [
          {
            component: RangeComponent,
            width: "60%",
            label: "Minimum Value",
            type: "number",
            id: "input3",
            placeholder: "",
          },
          {
            component: RangeComponent,
            width: "60%",
            label: "Maximum Value",
            type: "number",
            id: "input3",
            placeholder: "",
          },
        ],
      },
      {
        style: "display: flex",
        components: [
          {
            component: CheckBoxComponent,
            id: "input4", // Ensure IDs are unique
            label: "Private Field",
            description:
              "You won't be able to create an entry if there is an existing entry with identical content",
            value: "",
            placeholder: "",
          },
          {
            component: CheckBoxComponent,
            id: "input4", // Ensure IDs are unique
            label: "Enable Localization for this field",
            description: "The field can have different values in each language",
            value: "",
          },
        ],
      },
    ],
  },
  "Rich Text": {
    componentDescription: "A rich text editor with formatting options",
    basicSettings: [
      {
        style: "display: flex",
        components: [
          {
            component: TextComponent,
            id: "input1",
            type: "text",
            label: "Name",
            value: "",
            options: [],
            width: "50%",
            rule: "No space is allowed for the name of the attribute",
          },
        ],
      },
    ],
    advancedSettings: [
      {
        components: [
          {
            component: TextComponent,
            id: "input1",
            type: "text",
            label: "Default Value",
            value: "",
            options: [],
            width: "50%",
            placeholder: "",
          },
        ],
      },
      {
        style: "display: flex",
        componentLabel: "Settings",
        components: [
          {
            component: CheckBoxComponent,
            id: "input2", // Ensure IDs are unique
            label: "Required Field",
            description:
              "You won't be able to create an entry if this field is empty",
            value: "",
            placeholder: "",
          },
          {
            component: CheckBoxComponent,
            id: "input2", // Ensure IDs are unique
            label: "Enable Localization for this field",
            description: "The field can have different values in each language",
            value: "",
            placeholder: "",
          },
        ],
      },
      {
        style: "display: flex",
        components: [
          {
            component: RangeComponent,
            width: "60%",
            label: "Minimum Length",
            type: "number",
            id: "input3",
            placeholder: "",
          },
          {
            component: RangeComponent,
            width: "60%",
            label: "Maximum Length",
            type: "number",
            id: "input3",
            placeholder: "",
          },
        ],
      },
      {
        components: [
          {
            component: CheckBoxComponent,
            id: "input4", // Ensure IDs are unique
            label: "Private Field",
            description:
              "You won't be able to create an entry if there is an existing entry with identical content",
            value: "",
            width: "50%",
            placeholder: "",
          },
        ],
      },
    ],
  },
  Password: {
    componentDescription: "Password field with encryption",
    basicSettings: [
      {
        style: "display: flex",
        components: [
          {
            component: TextComponent,
            id: "input1",
            type: "text",
            label: "Name",
            value: "",
            options: [],
            width: "50%",
            rule: "No space is allowed for the name of the attribute",
          },
        ],
      },
    ],
    advancedSettings: [
      {
        components: [
          {
            component: TextComponent,
            id: "input1",
            type: "text",
            label: "Default Value",
            value: "",
            options: [],
            width: "50%",
            rule: "No space is allowed for the name of the attribute",
            placeholder: "",
          },
        ],
      },
      {
        style: "display: flex",
        componentLabel: "Settings",
        components: [
          {
            component: CheckBoxComponent,
            id: "input2", // Ensure IDs are unique
            label: "Required Field",
            description:
              "You won't be able to create an entry if this field is empty",
            value: "",
            placeholder: "",
          },
          {
            component: CheckBoxComponent,
            id: "input2", // Ensure IDs are unique
            label: "Enable Localization for this field",
            description: "The field can have different values in each language",
            value: "",
            placeholder: "",
          },
        ],
      },
      {
        style: "display: flex",
        components: [
          {
            component: RangeComponent,
            width: "60%",
            label: "Minimum Length",
            type: "number",
            id: "input3",
            placeholder: "",
          },
          {
            component: RangeComponent,
            width: "60%",
            label: "Maximum Length",
            type: "number",
            id: "input3",
            placeholder: "",
          },
        ],
      },
      {
        components: [
          {
            component: CheckBoxComponent,
            id: "input4", // Ensure IDs are unique
            label: "Private Field",
            description:
              "You won't be able to create an entry if there is an existing entry with identical content",
            value: "",
            width: "50%",
            placeholder: "",
          },
        ],
      },
    ],
  },
  Number: {
    componentDescription: "Numbers (integer, float, decimal)",
    basicSettings: [
      {
        style: "display: flex",
        components: [
          {
            component: TextComponent,
            id: "input1",
            type: "text",
            label: "Name",
            value: "",
            options: [],
            rule: "No space is allowed for the name of the attribute",
            placeholder: "",
          },
          {
            component: DropdownComponent,
            id: "input1",
            label: "Number Format",
            options: [
              { title: "integer" },
              { title: "float" },
              { title: "decimal" },
            ],
            placeholder: "",
          },
        ],
      },
    ],
    advancedSettings: [
      {
        components: [
          {
            component: TextComponent,
            id: "input1",
            type: "number",
            label: "Default Value",
            value: "",
            options: [],
            width: "50%",
            placeholder: "",
          },
        ],
      },
      {
        style: "display: flex",
        componentLabel: "Settings",
        components: [
          {
            component: CheckBoxComponent,
            id: "input2", // Ensure IDs are unique
            label: "Required Field",
            description:
              "You won't be able to create an entry if this field is empty",
            value: "",
            placeholder: "",
          },
          {
            component: CheckBoxComponent,
            id: "input2", // Ensure IDs are unique
            label: "Enable Localization for this field",
            description:
              "You won't be able to create an entry if there is an existing entry with identical content",
            value: "",
            placeholder: "",
          },
        ],
      },
      {
        style: "display: flex",
        components: [
          {
            component: RangeComponent,
            width: "60%",
            label: "Minimum Length",
            type: "number",
            id: "input3",
            placeholder: "",
          },
          {
            component: RangeComponent,
            width: "60%",
            label: "Maximum Length",
            type: "number",
            id: "input3",
            placeholder: "",
          },
        ],
      },
      {
        style: "display: flex",
        components: [
          {
            component: CheckBoxComponent,
            id: "input4", // Ensure IDs are unique
            label: "Private Field",
            description:
              "You won't be able to create an entry if there is an existing entry with identical content",
            value: "",
            placeholder: "",
          },
          {
            component: CheckBoxComponent,
            id: "input4", // Ensure IDs are unique
            label: "Enable Localization for this field",
            description: "The field can have different values in each language",
            value: "",
            placeholder: "",
          },
        ],
      },
    ],
  },
  Enumeration: {
    componentDescription: "List of values, then pick one",
    basicSettings: [
      {
        components: [
          {
            component: TextComponent,
            id: "input1",
            type: "text",
            label: "Name",
            value: "",
            options: [],
            width: "50%",
            rule: "No space is allowed for the name of the attribute",
          },
        ],
      },
      {
        components: [
          {
            component: EnumerationComponent,
            id: "input2",
            label: "Values (one line per value)",
            width: "50%",
            placeholder: "Ex:\nmorning\nnoon\nevening",
            value: "",
            options: [],
          },
        ],
      },
    ],
    advancedSettings: [
      {
        style: "display: flex",
        components: [
          {
            component: DropdownComponent,
            id: "input1",
            label: "Default Value",
            options: [],
            placeholder: "",
            value: "",
          },
          {
            component: TextComponent,
            label: "Name override for GraphQL",
            rule: "Allows you to override the default generated name for GraphQL",
            placeholder: "",
            value: "",
          },
        ],
      },
      {
        style: "display: flex",
        components: [
          {
            component: CheckBoxComponent,
            id: "input2", // Ensure IDs are unique
            label: "Required Field",
            description:
              "You won't be able to create an entry if this field is empty",
            value: "",
            placeholder: "",
            options: [],
          },
          {
            component: CheckBoxComponent,
            id: "input2", // Ensure IDs are unique
            label: "Enable Localization for this field",
            description: "The field can have different values in each language",
            value: "",
            placeholder: "",
            options: [],
          },
        ],
      },
      {
        components: [
          {
            component: CheckBoxComponent,
            id: "input3", // Ensure IDs are unique
            label: "Private Field",
            description:
              "You won't be able to create an entry if there is an existing entry with identical content",
            value: "",
            placeholder: "",
            options: [],
            width: "50%"
          },
        ],
      },
    ],
  },
  Date: {
    componentDescription: "A date picker with hours, minutes and seconds",
    basicSettings: [
      {
        style: "display: flex",
        components: [
          {
            component: TextComponent,
            id: "input1",
            type: "text",
            label: "Name",
            value: "",
            rule: "No space is allowed for the name of the attribute",
            placeholder: "",
          },
          {
            component: DropdownComponent,
            id: "input2",
            label: "Type",
            options: [],
            value: "",
            placeholder: "",
          },
        ],
      },
    ],
    advancedSettings: [
      {
        components: [
          {
            component: TextComponent,
            id: "input1",
            type: "date",
            label: "Default Value",
            value: "",
            width: "50%",
          },
        ],
      },
      {
        style: "display: flex",
        componentLabel: "Settings",
        components: [
          {
            component: CheckBoxComponent,
            id: "input2", // Ensure IDs are unique
            label: "Required Field",
            description:
              "You won't be able to create an entry if this field is empty",
            value: "",
          },
          {
            component: CheckBoxComponent,
            id: "input2", // Ensure IDs are unique
            label: "Unique Field",
            description:
              "You won't be able to create an entry if there is an existing entry with identical content",
            value: "",
          },
        ],
      },
      {
        style: "display: flex",
        components: [
          {
            component: CheckBoxComponent,
            id: "input3", // Ensure IDs are unique
            label: "Private Field",
            description:
              "You won't be able to create an entry if there is an existing entry with identical content",
            value: "",
          },
          {
            component: CheckBoxComponent,
            id: "input3", // Ensure IDs are unique
            label: "Enable Localization for this field",
            description: "The field can have different values in each language",
            value: "",
          },
        ],
      },
    ],
  },
  Media: {
    componentDescription: "Files like images, videos, etc",
    basicSettings: [
      {
        components: [
          {
            component: TextComponent,
            id: "input1",
            type: "text",
            label: "Name",
            rule: "No space is allowed for the name of the attribute",
            width: "50%",
            placeholder: "",
            value: "",
            options: [],
          },
        ],
      },
      {
        style: "display: flex",
        components: [
          {
            component: RadioComponent,
            id: "input2", // Ensure IDs are unique
            label: "Options",
            value: "",
            type: "radio",
            options: [
              {
                title: "Multiple Media",
                description:
                  "Best for titles name, link(URL). It also enables exact search on the field.",
              },
              {
                title: "Single Media",
                description:
                  "Best for descriptions, Biography, Exact search is disabled.",
              },
            ],
            placeholder: "",
          },
        ],
      },
      {
        components: [
          {
            component: UploadComponent,
            id: "input3",
            value: null,
            type: "file",
            label: "Upload Files",
            width: "50%",
          },
        ],
      },
    ],
  },
  Boolean: {
    componentDescription: "Yes or no, 1 or 0, true or false",
    basicSettings: [
      {
        style: "display: flex",
        components: [
          {
            component: TextComponent,
            id: "input1",
            type: "text",
            label: "Name",
            rule: "No space is allowed for the name of the attribute",
          },
          {
            component: DropdownComponent,
            id: "input2",
            label: "Type",
            options: [],
          },
        ],
      },
    ],
  },
  JSON: {
    componentDescription: "Data in JSON format",
    basicSettings: [
      {
        components: [
          {
            component: TextComponent,
            id: "input1",
            type: "text",
            label: "Name",
            rule: "No space is allowed for the name of the attribute",
            width: "50%",
          },
        ],
      },
    ],
  },
  Relation: {
    componentDescription: "Refers to a Collection Type",
    basicSettings: [],
  },
  UID: {
    componentDescription: "Unique Identifier",
    basicSettings: [
      {
        style: "display: flex",
        components: [
          {
            component: TextComponent,
            label: "Name",
            type: "text",
            placeholder: "e.g. slug, seoUrl, CanonicalUrl",
            rule: "No space is allowed for the name of the attribute",
            options: [],
            value: "",
          },
          {
            component: DropdownComponent,
            label: "Attached Field",
            options: [],
            value: "",
            placeholder: "",
          },
        ],
      },
    ],
  },
  Components: {
    componentDescription: "Group of fields that you can repeat or reuse",
    basicSettings: [
      {
        style: "display: flex",
        components: [
          {
            component: RadioComponent,
            id: "input1", // Ensure IDs are unique
            label: "Options",
            value: "",
            type: "radio",
            options: [
              {
                title: "Create a new component",
                description:
                  "A component is shared across types and components, it will be available and accessible everywhere.",
                conditionalComponents: [
                  {
                    component: TextComponent,
                    id: "input1",
                    type: "text",
                    label: "Display Name",
                    value: "",
                    placeholder: "",
                  },
                ],
              },
              {
                title: "Using an existing component",
                description:
                  "Reuse a component already craeted to keep your data consistent across content-types.",
                conditionalComponents: [
                  {
                    component: DropdownComponent,
                    id: "input2",
                    label:
                      "Select a category or enter a name to create a new one",
                    value: "",
                    options: [
                      {
                        title: "Component 1",
                      },
                      {
                        title: "Component 2",
                      },
                      {
                        title: "Component 3",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  Collections: {
    componentDescription: "collections",
    basicSettings: [
      {
        style: "display: flex",
        components: [
          {
            component: RadioComponent,
            id: "input1", // Ensure IDs are unique
            label: "Options",
            value: "",
            type: "radio",
            options: [
              {
                title: "Create a new component",
                description:
                  "A component is shared across types and components, it will be available and accessible everywhere.",
                conditionalComponents: [
                  {
                    component: TextComponent,
                    id: "input1",
                    type: "text",
                    label: "Display Name",
                    value: "",
                  },
                ],
              },
              {
                title: "Using an existing component",
                description:
                  "Reuse a component already craeted to keep your data consistent across content-types.",
                conditionalComponents: [
                  {
                    component: DropdownComponent,
                    id: "input2",
                    label:
                      "Select a category or enter a name to create a new one",
                    value: "",
                    options: [],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};
