<script  setup>
import { logos as logo } from "@/assets/radix-icons_component-3.svg";
import { onMounted, ref } from 'vue';


const props = defineProps({
    data: Array
});

onMounted(() => {
  console.log(props.data)
  getIcon(props.data.componentName);
});

// export default {
//   name: 'GenericContainer',
//   components: {
//     logo, // Register the imported SVG component
//   },
// };

const icon = ref("");

const getIcon = async (type) => {
  if(type == "Text")
    icon.value = require("@/assets/radix-icons_component-1.svg");
  else if(type == "Email")
    icon.value = require("@/assets/radix-icons_component-2.svg");
  else if(type == "Rich Text")
    icon.value = require("@/assets/radix-icons_component-3.svg");
  else if(type == "Password")
    icon.value = require("@/assets/radix-icons_component-4.svg");
  else if(type == "Number")
    icon.value = require("@/assets/radix-icons_component-6.svg");
  else if(type == "Enumeration")
    icon.value = require("@/assets/radix-icons_component-5.svg");
  else if(type == "Date")
    icon.value = require("@/assets/radix-icons_component-7.svg");
  else if(type == "Media")
    icon.value = require("@/assets/radix-icons_component-8.svg");
  else if(type == "Boolean")
    icon.value = require("@/assets/radix-icons_component-9.svg");
  else if(type == "JSON")
    icon.value = require("@/assets/radix-icons_component-10.svg");
  else if(type == "Collection")
    icon.value = require("@/assets/radix-icons_component-11.svg");
}
</script>

<template>
  <div class="genericParent">
      <div class="leftComp">
        <img :src="icon" />
        <span class="contentType"> {{props.data.value}} </span>
      </div>
      <span> {{props.data.componentName}} </span>
      <span> English(US), English(UK)</span>
      <span> 
        <v-icon name="la-edit-solid"/>
        <v-icon class="trashIcon" name="fa-trash-alt"/>
      </span>
  </div>
</template>

<style scoped>

.genericParent {
  min-height: 20px;
}

.trashIcon {
  color: red;
}

.genericParent {
  display: flex;
  flex-direction: row;
  border: solid 1px;
  border-radius: 1rem;
  padding: 1rem;
  margin: 1rem;
  justify-content: space-between;
  font-size: 0.9rem;
  font-weight: 400;
}

.leftComp {
  display: flex;
  flex-direction: row;
}

.contentType {
  margin-left: 1rem;
}
</style>
