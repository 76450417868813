<script setup>
import GenericContainer from "./GenericContainer.vue";
import DynamicComponent from "./DynamicComponent.vue";
import { onMounted, ref, computed } from "vue";
import { useRoute } from "vue-router";
import Button from "@/components/AdminWidgets/Button.vue";
import ContentBox from "@/components/ContentBox/ContentBox.vue";
import { useStore } from "vuex";

const props = defineProps({
    data: Array
});

// const data2 = 
//   [
//     {
//       "type": "checkbox",
//       "label": "whatever",
//     },
//     [
//       "parent",
//       {
//         "type": "text",
//         "label": "Name",
//       },
//       {
//         "type": "checkbox",
//         "label": "Agree"
//       },
//       [
//         "parent2",
//         {
//           "type": "text",
//           "label": "Name",
//         },
//         [
//           "parent99",
//           {
//             "type": "text",
//             "label": "Name",
//           },
//         ]
//       ]
//     ],
//     [
//       "parent3",
//       {
//         "type": "text",
//         "label": "Name",
//       },
//       {
//         "type": "checkbox",
//         "label": "Agree"
//       }
//     ]
//   ]

const showModal = ref(false);

function checkType(value) {
    if (Array.isArray(value)) {
        return DynamicComponent; // Return the component object
    } else if (value && typeof value === 'object') {
        return GenericContainer; // Return the component object
    } else {
        return null; // Optionally handle other cases or return null
    }
}

const store = useStore();
const items = computed(() => store.getters.items);

// Reactive states
const isOpen = ref(false);
const isAddFieldModalOpen = ref(false);

// Function to toggle the component
const toggleComponent = () => {
  isOpen.value = !isOpen.value;
};

// function to open add field modal
const handleToggleAddField = () => {
  isAddFieldModalOpen.value = !isAddFieldModalOpen.value;
};

const openComponentModal = async () => {
  showModal.value = !showModal.value;
  if(!showModal.value) {
    let temp = items.value[0]
    await addToArray(temp, props.data[0], items.value[items.value.length - 1]);
    store.dispatch("updateItem", {index: 0, newItem: temp})
    console.log(JSON.stringify(items.value[0], null, 2));
  }
}

async function addToArray(arr, name, newElement) {
  let targetArray = [];
    // Helper function to search for the array with the matching name
    function findArray(arr, name) {
        for (let i = 0; i < arr.length; i++) {
            if (Array.isArray(arr[i])) {
                // Check if the first element is the name we are looking for
                if (typeof arr[i][0] === 'string' && arr[i][0] === name) {
                    return arr[i]; // Return the array if found
                }
                // Recursively search nested arrays
                const nestedResult = findArray(arr[i], name);
                if (nestedResult) {
                    return nestedResult;
                }
            }
        }
        return null; // Return null if the array with the name is not found
    }

    // Find the target array using the helper function
    if(arr && arr.length)
    {
      targetArray = findArray(arr, name);
    }

    if (targetArray) {
        targetArray.push(newElement); // Add the new element (array or object) to the target array
    } else {
        console.error("Array with the given name not found.");
    }
}

</script>

<template>
    <!-- <div class="dynamicParent">
        <h1>{{ data[0] }}</h1>
        <component v-for="(d, index) in data" :key="index" :is="checkType(d)" :data="d"/>
    </div> -->
    <div class="main-frame">
    <div class="container">
      <div class="margin-left-1rem" v-if="loading"><span>Loading...</span></div>
      <div class="item">
        <template v-if="!true">
          <div class="add-container"><span class="btn-desc-container">
              <button class="add-btn" @click="handleToggleAddField"><span>
                  <v-icon name="hi-plus-sm" fill="#606060"></v-icon><span>Add Field</span></span></button>
              <p>The field type defines what content can be stored. For instance, a text field accepts titles and descriptions, and a media field is used for images and videos.</p></span></div>
        </template>
        <template v-else>
          <template v-if="!loading">
            <section>
              
              <div class="body-container">
                <div class="component-container"> 
                    <span class="toggle" @click="toggleComponent" :class="{rotated: isOpen}">
                        <v-icon class="icon" name="bi-caret-right-fill"></v-icon></span>
                    <div class="genericParent">
                        <div class="leftComp">
                            <!-- <img src="@/assets/radix-icons_component-13.svg"/> -->
                            <img src="@/assets/radix-icons_component-13.svg"/>
                            <span class="contentType"> {{props.data[0]}} </span>
                            </div>
                            <span> Component </span>
                            <span> English(US), English(UK)</span>
                            <span> 
                                <v-icon name="la-edit-solid"/>
                                <v-icon class="trashIcon" name="fa-trash-alt"/>
                            </span>
                            
                        </div>
                        
                </div>
                <div class="subcomponent-container" v-if="isOpen"> 
                  
                  <component v-for="(d, index) in data" :key="index" :is="checkType(d)" :data="d"/>
                  
                </div>
                <button class="addComponentBtn" @click="openComponentModal">Add component</button>
              </div>
              
            </section>
          </template>
        </template>
      </div>
    </div>
  </div>
  <ContentBox :isOpen="showModal" :close="openComponentModal" />
</template>

<style scoped>

.dynamicParent {
    border: solid;
    padding: 10px;
}

/* .headingName {
    text-align: left;
    padding-left: 3rem; 
}

.headingContent {
    margin-left: -1rem;
}

.headingInternal {
    margin-left: 1rem;
} */

 .headerChild{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
 }

 .headingHeader {
    border: solid 1px;
    padding-left: 4.5rem;
    padding-right: 1.5rem;
 }

.top-btn {
  padding: 10px 8px;
  border: 1px solid #606060;
  background-color: white;
  border-radius: 6px;
  display: flex;
  align-items: center;
  color: #606060;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
}
.add-btn {
  padding: 8px 10px;
  border: 1px solid #606060;
  background-color: white;
  border-radius: 6px;
  display: flex;
  align-items: center;
  color: #606060;
  cursor: pointer;
}

.for-left {
  .pageName-icon {
    display: flex;
    gap: 0.6rem;
    align-items: center;
  }
}

.item {
  .add-container {
    margin: 0 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75vh;
    width: 98%;
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    box-shadow: 0px 4px 4px 0px #00000040;

    .btn-desc-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 400px;

      p {
        text-align: center;
        font-size: 14px;
      }
    }
  }

  section {
    width: 95%;
    margin: 2%;
    color: #606060;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-sizing: border-box;

    h3,
    p {
      margin: 0;
    }

    .body-container {
      border: 1px solid #00000040;
      padding: 10px;

      .name-desc {
        display: flex;
        margin-left: 1rem;
        align-items: center;

        img {
          width: 40px;
          height: 40px;
        }

        span {
          text-align: left;
          margin-left: 10px;

          h3 {
            color: #0146f4;
            font-size: 16px;
          }

          p {
            font-size: 12px;
          }
        }
      }

      .component-container {
        border: 1px solid #0146f4;
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .component {
          display: flex;
          padding: 8px 0px;
          align-items: center;

          .component-name {
            flex: 2.5;
            text-align: left;
            display: flex;
            align-items: center;

            .toggle {
              width: 1rem;
            }
          }
          div {
            flex: 1;
            text-align: center;
          }
        }
      }

      .subcomponent-container {
        border: 1px solid #0146f4;
        border-radius: 10px;
        margin-top: 8px;

        .subcomponent {
          display: flex;
          padding: 8px 0px;
          border-radius: 10px;
          align-items: center;
          margin-bottom: 8px;

          .subcomponent-name {
            flex: 2.5;
            text-align: left;
            display: flex;
            align-items: center;

            .toggle {
              width: 1.5rem;
            }
          }
          div {
            flex: 1;
            text-align: center;
          }
        }
      }
    }
  }
}

.rotated {
  transform: rotate(90deg);
  transition: transform 0.3s ease-in-out;
}

span {
  display: inline-block;
  transition: transform 0.3s ease-in-out;
}

.border-blue {
  border: 1px solid #0146f4;
}

.genericParent {
  min-height: 20px;
}

.trashIcon {
  color: red;
}

.genericParent {
    min-width:94%;
  display: flex;
  flex-direction: row;
  border-radius: 1rem;
  padding: 0.5rem;
  justify-content: space-between;
  font-size: 0.9rem;
  font-weight: 400;
}

.leftComp {
  display: flex;
  flex-direction: row;
}

.contentType {
  margin-left: 1rem;
}
</style>
